import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import ProductSlider from '../components/Shop/ProductSlider';
import ProductsDetailsTabs from '../components/Shop/ProductsDetailsTabs';
import * as Icon from 'react-feather';
import { Link } from "gatsby";


import ShopImage1 from "../assets/images/shop-image/bosh-akulu-supurge.png";
// import Loadable from '@loadable/component';
// const OwlCarousel = Loadable(() => import('react-owl-carousel3'));




import { resetIdCounter, Tab, Tabs, TabList, TabPanel } from 'react-tabs';
resetIdCounter();



const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    items: 1,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    navText: [
        "<i class='bx bx-chevron-left'></i>",
        "<i class='bx bx-chevron-right'></i>"
    ],
};


const ProductDetailsSupurge = () => {
    // const [display, setDisplay] = React.useState(false);

    // React.useEffect(() => {
    //     setDisplay(true);
    // }, [])
    return (
        <Layout>
            <SEO title="Bosch Akülü Süpürge" />
            <Navbar />
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <h2 style={{ margin: '20px' }} >SIFIR KUTUSUNDA GARANTİLİ </h2>
                            <h2 style={{ margin: '20px' }} >BOSCH AKÜLÜ SÜPÜRGE</h2>
                            <div className="shop-details-area ptb-80">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5">
                                            <div className="single-image-box">
                                                <img src={ShopImage1} alt="img" />
                                            </div>
                                            {/* <>
                                                {display ? <OwlCarousel
                                                    className="products-details-image products-details-image-slides owl-carousel owl-theme"
                                                    {...options}
                                                >
                                                    <div className="single-image-box">
                                                        <img src={ShopImage1} alt="img" />
                                                    </div>

                                                    <div className="single-image-box">
                                                        <img src={ShopImage2} alt="img" />
                                                    </div>

                                                    <div className="single-image-box">
                                                        <img src={ShopImage3} alt="img" />
                                                    </div>

                                                    <div className="single-image-box">
                                                        <img src={ShopImage4} alt="img" />
                                                    </div>

                                                    <div className="single-image-box">
                                                        <img src={ShopImage5} alt="img" />
                                                    </div>
                                                </OwlCarousel> : ''}
                                            </> */}
                                        </div>

                                        <div className="col-lg-7">
                                            <div className="products-details">
                                                <h3>AKÜLÜ SÜPÜRGE</h3>

                                                <div className="price">
                                                    <span>0.00₺</span> YERİNDE TAKAS
                                                </div>

                                                <ul className="rating">
                                                    <li><i className="flaticon-star-1"></i></li>
                                                    <li><i className="flaticon-star-1"></i></li>
                                                    <li><i className="flaticon-star-1"></i></li>
                                                    <li><i className="flaticon-star-1"></i></li>
                                                    <li><i className="flaticon-star-1"></i></li>
                                                </ul>

                                                <p>Sıfır kutusunda sizler için tedarik edilmiş bir üründür</p>
                                                <p>Kenarların ve köşelerin temizlenmesi için akülü elektrik süpürgesi</p>
                                                <p>3 adet LED'li pratik akü şarj durumu göstergesi </p>
                                                <p>Akü ile birlikte ağırlığı 0,87 kg</p>
                                                <p> Karton Kutuda Sadece Süpürge ve Mikro Filtre Bulunmaktadır.Şarj Cihazı ve Akü Teslimat Kapsamında Dahil Değildir.</p>
                                                <p>Stokta 0 adet olup en uygun teklif veren müşterimizle takas edilecektir</p>
                                                <p>Fırsatı kaçıran müşterilerimize anlayışı için teşekkür ederiz</p>

                                                <div className="availability">
                                                    Stok Durum: <span> 0 Adet Mevcuttur</span>
                                                </div>

                                                <Link to="#" className="btn btn-light">
                                                    STOK TÜKENDİ
                                                </Link>




                                                <div className="custom-payment-options">
                                                    <span>Yerinde Takas Whatsapp Hattı</span>
                                                    +90 332 606 20 54

                                                </div>
                                                <p>Ürünle ilgili merak ettiğiniz konuları whatsapp hattımızdan sorabilirisiniz</p>
                                                <p>Yoğunluktan kaynaklı geç dönüş yapılabilmektedir. Anlayışınız için teşekkür ederiz</p>



                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <ProductsDetailsTabs />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer />
        </Layout>
    )
}

export default ProductDetailsSupurge